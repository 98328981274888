/* Container principal */
body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f7fa;
    margin: 0;
    padding: 0;
}

.lista-laboral-container {
    
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 85%; /* Já estava duplicado, agora unificado */
}

.lista-laboral-h1 {
    text-align: center;
    color: #686b6b;
    margin-bottom: 20px;
    font-size: 2.2em;
    font-weight: bold;
}

.lista-laboral-input, .lista-laboral-select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    width: 220px;
    margin-bottom: 15px;
    margin-right: 10px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    width: 100%; /* Removido duplicação de largura */
}

.lista-laboral-input:focus, .lista-laboral-select:focus {
    border-color: #056b20;
    box-shadow: 0 0 5px rgba(0, 106, 136, 0.3);
}

.lista-laboral-select {
    width: 200px;
    background-color: #fff;
    cursor: pointer;
}

/* Botão de busca */
.lista-laboral-button {
    background-color: #253221;
    color: white;
    border: none;
    padding: 20px;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 12px;
    height: 48px; 
    align-items: center;
    justify-content: center;
    display: flex;
    
}

.lista-laboral-button:hover {
    background-color: #366528; /* Unificado o hover */
}

/* Lista de resultados */
.lista-laboral-ul {
    list-style: none;
    padding: 0;
    margin: 10px 0;
}

.lista-laboral-li {
    background-color: #e9f1f5;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-left: 4px solid #366528;
    transition: background-color 0.3s ease;
}

.lista-laboral-li:hover {
    background-color: #d5e7ec;
}

.lista-laboral-li strong {
    color: #333;
    font-weight: 600;
}

/* Estilo responsivo para telas menores */
@media (max-width: 768px) {
    .lista-laboral-container {
        padding: 15px;
    }
    
    .lista-laboral-h1 {
        font-size: 2em;
    }
    
    .lista-laboral-input, .lista-laboral-select {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .lista-laboral-button {
        width: 100%;
    }
    
    .lista-laboral-li {
        flex-direction: column;
        align-items: flex-start;
    }
}

/* Tabela de listagem */
.lista-laboral-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.lista-laboral-table th, .lista-laboral-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: center; /* Unificado o alinhamento */
}

.lista-laboral-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.lista-laboral-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.lista-laboral-table tr:hover {
    background-color: #f1f1f1;
}

/* Container de filtros */
.filter-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
}
