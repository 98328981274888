/* Loading.module.css */

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; /* Usando 100vw para garantir que ocupe a largura completa da janela */
  height: 100vh; /* Usando 100vh para garantir que ocupe a altura completa da janela */
  background-color: #253221; /* Um fundo semi-transparente para destacar o loading */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
  overflow: hidden;
  z-index: 10000; /* Oculta a barra de rolagem do componente de fundo */
}

.loading img {
  width: 100px; /* Largura do GIF de carregamento */
  height: 100px; /* Altura do GIF de carregamento */
  margin-bottom: 20px; /* Espaçamento inferior entre o GIF e o texto */
}

.loading p {
  color: white;
  font-size: 18px;
  text-align: center;
  margin: 0; /* Remove margens padrão */
  padding: 0; /* Remove preenchimento padrão */
}
